.multiplex__main {
    width: 100vw;
    min-width: fit-content;
    min-height: 100vh;
    font-family: 'Lexend Deca', sans-serif;
    text-align: left;
    padding: 2rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: fit-content;
}

.multiplex__main__header,
.multiplex__main__body {
    display: flex;
    justify-content: space-evenly;
}

.multiplex__main__header {
 padding: 1rem 0;
}

.multiplex__main__col {
    display: flex;
    width: 38%;
    min-width: 540px;
    flex-direction: column;
    align-items: center;
}

.multiplex__main__col:first-child {
    margin-right: 1rem;
}

.multiplex__main__header .multiplex__main__col {
    justify-content: center;
}

.multiplex__main__col:first-child {
    width: 52%;
}

.multiplex__main__multiplex-card__infos img {
    width: auto;
    max-height: 100px;
    margin: auto;
}

.multiplex__main__multiplex-card__title {
    font-size: 30px;
}

.multiplex__main__multiplex-card__title-span {
    margin-left: 6px;
}

.multiplex__main__video-container {
    width: 100%;
    padding: 6px;
    border-radius: 25px;
}

.multiplex__main__video {
    width: 100%;
    aspect-ratio: 16 / 9;
    background-color: black;
    border-radius: 20px;
}

.multiplex__main__multiplex-tracker {
    position: relative;
    padding: 0.5rem;
    margin: 2rem;
}

.multiplex__main__multiplex-tracker__content {
    position: relative;
    z-index: 2;
}

.multiplex__multiplex-tracker__heart {
    position: absolute;
    height: 106px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.multiplex__main__dashboard-header {
    font-size: 24px;
    font-weight: bold;
    max-height: 200px;
}

.multiplex__main__dashboard-header img {
    max-height: 100%;
}

.multiplex__main__conditional-box-container {
    display: flex;
    width: 100%;
}

.multiplex__main__conditional-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: fit-content;
}

.multiplex__main__dual-box .multiplex__container__content {
    height: 100%
}

.multiplex__main__single-box {
    width: 100%;
    min-width: fit-content;
}

.multiplex__main__location-pile {
    padding: 0.5rem 2rem;
    border-radius: 25px;
    width: fit-content;
    margin: 0 auto 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 24px;
    font-weight: bold;
}

.multiplex__main__location-pile svg,
.multiplex__main__highlight-location svg {
    height: 28px;
}

.multiplex__main__projects-list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: fit-content;
    margin: auto;
    gap: 1rem;
}

.multiplex__main__highlight-location {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 20px;
}

.multiplex__main__highlight-location__counter {
    font-size: 24px;
    font-weight: bold;
}

.dashed-bar__container {
    margin-top: 2rem;
}