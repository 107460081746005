.multiplex-jauge__main {
    position: relative;
    width: 100vw;
    height: 100vh;
    font-family: 'Lexend Deca', sans-serif;
}

.multiplex-jauge__flux {
    width: 100vw;
    height: 100vh;
    background-color: black;
}

.multiplex-jauge__overlay {
    position: absolute;
    bottom: 3rem;
    left: 3rem;
    right: 3rem;
    background: radial-gradient(circle at 75% 50%, rgba(1, 70, 252, 0.8) 0%, rgba(0, 17, 45, 0.8) 60%);
    border-radius: 1rem;
}

.multiplex-jauge__overlay__inner-container {
    position: relative;
    display: flex;
    width: 100%;
    height: auto;
    padding: 2rem 4rem;
}

.multiplex-jauge__title {
  max-width: 380px;
  font-size: 22px;
  font-weight: bold;
  margin: 0.5rem 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
.multiplex-jauge__title img {
  max-height: 100px;
}

.multiplex-jauge__progress-bar {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: -24px 2rem 0;
    font-size: 18px;
    height: 100%;
    justify-content: center;
    width: 85%;
}

.multiplex-jauge__progress-bar__legend {
    display: flex;
    justify-content: space-between;
}

.multiplex-jauge__progress-bar__progress {
    font-weight: bold;
    color: #FF4700;
}

.multiplex-jauge__progress-bar__location {
    font-weight: bold;
}

.multiplex-jauge__counter {
    text-align: center;
}

.multiplex-jauge__counter-label {
    font-size: 20px;
}

.multiplex-jauge__counter-state {
    font-weight: bold;
    font-size: 40px;
    margin: 4px 0;
}