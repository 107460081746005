.bottom-overlay {
    position: absolute;
    bottom: 3rem;
    left: 3rem;
    right: 3rem;
    min-width: 1220px;
    border-radius: 1rem;
}

.bottom-overlay__container {
    position: relative;
}

.bottom-overlay__background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 1rem;
    opacity: 0.8;
}

.bottom-overlay__inner-container {
    position: relative;
    display: flex;
    width: 100%;
    height: auto;
    padding: 0 3rem;
    min-height: 8rem;
    max-height: 10rem;
}

.bottom-overlay__left,
.bottom-overlay__right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.bottom-overlay__middle {
    display: flex;
    flex-grow: 1;
    justify-content: space-evenly;
}